import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomerSupportLayout from './_layout';
import Page from '../../components/Page';
import Accordion from '../../components/Accordion';

export default function FAQPage(props) {
  const { t } = useTranslation();

  return (
    <CustomerSupportLayout subtitle={t('support.faq.menu.title')} {...props}>
      <Page
        title={t('support.faq.page.title')}
        subtitle={t('support.faq.page.subtitle')}
      >
        <Accordion
          entries={t('support.faq.content', {
            returnObjects: true,
          })}
        />
      </Page>
    </CustomerSupportLayout>
  );
}
