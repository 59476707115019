import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../../components/seo';
import { Layout } from '../../layout';
import { HeaderCompact, NavLink } from '../../components/Header';

const CustomerSupportLayout = ({ subtitle, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="Customer Support" keywords={['weboxit', 'boxit']} />
      <HeaderCompact title={t('support.submenu.title')} subtitle={subtitle}>
        <NavLink
          to="/customer_support/24_7_live_support"
          activeClassName="header-active-link"
        >
          {t('support.submenu.24_7_support')}
        </NavLink>
        <NavLink
          to="/customer_support/faq"
          activeClassName="header-active-link"
        >
          {t('support.submenu.faq')}
        </NavLink>
        {/* <NavLink
          to="/customer_support/contact_us"
          activeClassName="header-active-link"
        >
          {t('support.submenu.contact_us')}
        </NavLink> */}
      </HeaderCompact>
      {children}
    </Layout>
  );
};

export default CustomerSupportLayout;
