import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';

import Container from '../../components/container';

const PageTitle = props => (
  <Heading
    {...props}
    height="25px"
    fontSize={['20px', '40px', '56px']}
    lineHeight="0.44"
    letterSpacing="-2.2px"
    color="blues.twilight"
  />
);

const PageSubTitle = props => (
  <Text
    {...props}
    mt="27px"
    height="34px"
    fontSize={['12px', '14px', '20px']}
    lineHeight="1.5"
    color="blues.peacock"
  />
);

const Page = ({ title, subtitle, children }) => (
  <Container>
    <Flex flexDirection="column" flexWrap="wrap">
      <Box pt={['46px', '50px', '64px']}>
        <PageTitle>{title}</PageTitle>
        <PageSubTitle>{subtitle}</PageSubTitle>
      </Box>
      {children}
    </Flex>
  </Container>
);

export default Page;
