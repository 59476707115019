import React from 'react';
import styled from 'styled-components';
import { Box, Image, Text } from 'rebass';

import QuestionIcn from '../../icons/question@2x.png';

// $cAccent: #3978EF;
// $cBlack: #121212;
// $cGray: #333;
// $cLightGray: rgba(#000, 0.1);

const AccordionList = styled.ul`
  list-style: none;
  margin-top: 38px;
  padding: 0;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(darken(#3978ef, 20%), 0.06),
    0 20px 30px -10px rgba(darken(#3978ef, 20%), 0.2);
`;

const AccordionListItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.lightBlueGrey};
`;

const Accordion = ({ entries }) => (
  <AccordionList>
    {entries.map((entry, key) => {
      return (
        <AccordionListItem key={key}>
          <AccordionItem {...entry} />
        </AccordionListItem>
      );
    })}
  </AccordionList>
);

const AccordionItemLine = styled.div`
  cursor: pointer;
  display: block;
  padding: 1.5rem 1.2rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  position: relative;
`;

const AccordionItemTitle = props => (
  <>
    <Image width={['16px', '22px', '22px']} src={QuestionIcn} mr="15px" />
    <Text
      {...props}
      fontSize={['14px', '18px', '25px']}
      lineHeight="1.04"
      color="blues.peacock"
    />
  </>
);

const AccordionItemParagraph = props => (
  <Text
    {...props}
    px={['20px', '40px', '40px']}
    fontSize={['12px', '12px', '15px']}
    lineHeight="2"
    letterSpacing="0.7px"
    color="marine"
  />
);

const AccordionItemIcon = styled.span`
  transform: ${props => (props.opened ? 'rotate(180deg)' : '')};
  min-width: 32px;
  height: 2rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAyFJREFUaAXtl0tPE1EUgM+B8nAwxRJDHyyMqRvBBQ91ZYINGsNL0NgiakyIkd/Bf3CHMTFRMWkRiUQFQYrixqhITHgsjHFhDMQEAkpBIbneaefCHTvDPDrDxtvNvT3nPr7v3M6jAOIjKiAqICogKiAqICogKmC7Amh7psHEqmi8cJ78jEIe8QSJN/4tEVs3mGIr7YpA6ZUHvtXN1AgQOKFQzUgF0LD28OaiLcpdJjkuoMCPUvg61b6Ic5KHRJyWcFRAF56ZuCDhmIAhvEsSjgiYhndBImcBy/AOS+QkYBveQQnbAjnDOyRhS8AxeAck8tgaZlsFfizrPq+1AOIbQBzWSqlihBxNbWGypPO2XxU38cWSAFf5WhNrTx4Eb2M4EGoHwOeG421KmBbg4NVPWC0yxNfl+4oafyRivz7favodDoYu0JN4pjVUFbMhYUrAMnxxYdPivetrDC4tEQhdpBJPWUy3tShheBFbg4dX5cVFzTw8Dyq/oc7C6gAQ0szHNfsmXzt2FbAEDzgRLPM3f+9tTWkCKcG0BFl5RL+27DYunTMhoStgCR4xGfT5W4zgGbByEv30JFpZTLc1kNC8BqzBw7gVeBl0JhH7UwneS/SaeKILzhIG10TWCViCB3xZgd5Wu/+26rrfF0wtfUxQ1jbGq9vqnITqBOQFVzfXh809pGAsF3gZ9EPv8c3aspooPYlBXXCWyJzEeKh7SGIhuVUJTC9Nt9Pf5Ul+gGYfcbQCSs/brTy/ZlrCVx0DhMd8XLNPSOXi8sI1PqcS4BP6fXxxqCTfEXi2R0aipoM+sQdYzGyrEqguqx6klXinP5nC789v+3q3a0N/jL2MLFF/LNxBf07yLVb7gzjr9wXu80mVgLyIt0A6RyWm+EHpPsKIW/Bsr4meyFZ9Vfgy3b+fxbh2XoL8hn9v1SoBefBK39VlKnGGHufb7cmIQ+FAhSuV395D6WQkjnQiYt9ODj9J6ImsJboWdmKZXtZtlA043ZP0TM59OYsENrbiN5IsvpetJ3rnFMnDA4fLA6Py+9Re7i32EhUQFRAVEBUQFRAV+C8q8BdKo4AR90jYHgAAAABJRU5ErkJggg==);
`;

const AccordionItemInner = styled.div`
  max-height: ${props => (props.opened ? '100rem' : 0)};
  overflow: hidden;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
`;

const AccordionItemContent = styled.div`
  opacity: ${props => (props.opened ? 1 : 0)};
  transform: ${props => (props.opened ? 'translateY(0)' : 'translateY(-1rem)')};
  transition-delay: ${props => (props.opened ? '0.2s' : '0.2s')};
  transition-timing-function: ${props =>
    props.opened ? 'ease-in-out' : 'linear, ease'};
  transition-duration: ${props => (props.opened ? '0.2s' : '0.1s')};
  transition-property: 'opacity, transform';
  padding: 0 1.2rem 1.2rem;
`;

class AccordionItem extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const {
      props: { paragraph, title },
      state: { opened },
    } = this;

    return (
      <div
        onClick={() => {
          this.setState({ opened: !opened });
        }}
      >
        <AccordionItemLine>
          <AccordionItemTitle>{title}</AccordionItemTitle>
          <Box mx="auto" />
          <AccordionItemIcon opened={opened} />
        </AccordionItemLine>
        <AccordionItemInner opened={opened}>
          <AccordionItemContent opened={opened}>
            <AccordionItemParagraph>{paragraph}</AccordionItemParagraph>
          </AccordionItemContent>
        </AccordionItemInner>
      </div>
    );
  }
}

export default Accordion;
